.custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .tablepage{
     
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        /* justify-content: flex-start; */
  }

  .innertabcont{
      margin-top: 40px;
      width: 80%;
      height: 100%;
  }

  .datepicker{
    display: flex;
    justify-content: flex-start;
    width: 100%;

  }
  @media only screen and (max-width: 768px)
  {
    .innertabcont{
        margin-top: 0px;
        width: 100%;
    }
  }