
.loading{
    height: calc(50vh - 70px);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingdashpage
{
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.containerland{
    width: 80%;
    /* margin: 40px; */
    /* padding: 50px; */
}

.landinner{
    margin-top: 40px;
    font-family: 'Rubik', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.landinner h1{
    font-size: 20px;
}