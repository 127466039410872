@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Rubik:ital,wght@1,500&display=swap');
.navbarcontainer {
	width: 100vw;
	height: 70px;
    display: flex;
    background-color: #111111;
    position: fixed;

    justify-content: space-between;
    z-index: 1;
}

.logo{
    width: auto;
    height: 70px;
}

.titledivfornav{
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: flex-start;
    height: 70px;
    margin-left: 20px;
    /* justify-content: center; */
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
}

.navbarbody{
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
}

.navbarbody i{
    display: none;
 }
 
 

@media only screen and (max-width:768px) {
    .navbarbody i{
        font-size: 22px;
        color: white;
        /* transition: transform 0.3s ease-in; */
        display: flex;
        cursor: pointer;

    }
    
    .iconrotate{
        transform: rotateY(180deg);
 

    }
    .titledivfornav{
        margin-left: 0px;
    }
    
}

