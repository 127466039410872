.searchandfilterpage{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.searchandfilcont{
    width: 80%;
    /* max-width: 1200px; */
    /* background-color: black; */
}

.searcheading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.searchbar{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.searchbar input{
    width: 60%;
    height: 40px;
    border-radius: 0%;
}

.searchbar button{
    border: none;
    /* background-color: blueviolet; */
    background-color: black;
    color: white;
    height: 40px;
}

.sidefilter{
    height: calc(100vh - 70px);
    margin-top: 70px;
    width: 350px;
    position: fixed;
    right: 0px;
    top: 0px;
    background-color: rgb(255, 255, 255) ;
    /* background-color: blueviolet; */
    z-index: 5;
    transition: right 0.5s ease-in;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.sidefilteropen{
    right: -350px;
   

}

.filterbtn{
    position: absolute;
    top: 100px;
    right: 40px;
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 20px;
    background-color: rgb(255, 255, 255);
    transition: right 0.5s ease-in;
    /* border-radius: ; */
}

.filterbtnonclick{
    right: 340px;
}

.arrow{
    -webkit-animation: mover 0.7s infinite  alternate;
    animation: mover 0.7s infinite  alternate;
    
}

.sidefiltercont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding: 40px;
    font-family: 'Rubik', sans-serif;
    overflow: auto;
}

.sidefiltercont input{
    height: 17px;
    width: 17px;
}

.sidefiltercont span{
    font-weight: 600px;
    font-size: 20px;
}

.sidefilterhead{
    color: rgb(0, 0, 0);
}

.filterobjects{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
}


.departmentobj{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

}

.fildepartchoice{
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.departch1,.departch2{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.stipendobj{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

}

.slimf{
    margin-top: 20px;
}

.sliderdiv{
    width: 90%;
}

.typeofjobobj{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;

}

.typeofchoicecontainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 30px;
    margin-top: 20px;
}

.typeofchoicecontainer label{
    margin-left: 10px;
}

.jobtobj{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;


}

.jobtcont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 30px;
    margin-top: 10px;
}
.jobtcont label{
    margin-left: 10px;
}

.iconfil{
    font-size: 25px;
}

@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateX(-10px); }
}

@media only screen and (min-width:768px){
    .nope{
        display: none;
    }
}

@media only screen and (max-width:768px){
    .searchbar input{
        width: 100%;
        height: 40px;
    }

    .sidefilter{
        width: 300px;
    }

    .sidefilteropen{
        right: -300px;
        
    }

    .filterbtn{
        right: 20px;
    }
    
    .filterbtnonclick{
        right: 284px;
    }

    .iconfil{
        font-size: 22px;
    }

    .sidefiltercont{
        padding: 30px;
    }

    
    .slimf{
        margin-top: 10px;
    }

    .stipendobj{
        margin-bottom: 10px;
    }

    .typeofjobobj{
        margin-bottom: 10px;

    }

    .typeofchoicecontainer{
        margin-top: 10px;
    }
}