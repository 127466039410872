@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

.sidenav {
	height: calc(100vh - 70px);
	
	display: flex;
	position: fixed;
	align-items: center;
	z-index: 5;
	margin-top: 70px;
	/* width: 70px; */

}

.openwidth{
	width: 70px;
}

.sidenavcontainer {
    /* margin-top: 70%; */
	height: 70%;
	background-color: rgb(0, 0, 0);
	width: 60px;
	border-radius: 20px;
	margin-left: 7px;
	transition: width 0.4s ease-in;

}

.blue{
	color: blueviolet;
}

.sidenavinnercontainer {
	display: flex;
	flex-direction: column;
	/* height: 300px; */
	align-items: flex-start;
}

.sidenavinnercontainer i {
	color: white;
	/* background-color: aqua; */
	padding: 10px;
	width: 40px;
	border-radius: 10px;
	font-size: 20px;
	margin-left: 10px;
	margin-right: 10px;
}

.sidenavcontainericons:hover {
	background-color: blueviolet;
}

.sidenavonclick {
	height: 70%;
	background-color: black;
	width: 200px;
	border-radius: 20px;
	margin-left: 7px;
}

.navdiv {
	display: flex;
	align-items: center;
}

.navdiv span {
	margin-left: 20px;
	color: white;
	font-family: "Rubik", sans-serif;
	font-weight: 500;
}

.none {
	display: none;
}

.navmargin {
	margin-top: 40px;
	margin-bottom: 40px;
}

.filters {
	margin-top: -30px;
	color: white;
	font-family: "Rubik", sans-serif;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
	/* transition: height 0.2s ease-in-out; */
	display: block;
}

.filtercontainer {
	display: flex;
	flex-direction: column;
	width: 120px;
	height: 120px;
	justify-content: space-evenly;
	align-items: flex-start;
	margin-left: 50px;
}
.fil input {
	margin-right: 10px;
	transform: scale(1.5);
}

.opacity {
	opacity: 0;
	/* display: none; */
	height: 0;
}

.mbt {
	margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
	.sidenav {
		left: -200px;
		transition: left 0.3s ease-in;
	}

	.sideonshow {
		left: 0px;
	}

	.sidenavcontainer {
		width: 50px;
		border-radius: 20px;
		background-color: rgb(135, 35, 230);
	}

	.sidenavonclick {
		height: 70%;
		background-color: black;
		width: 170px;
		border-radius: 20px;
		margin-left: 7px;
	}

	.sidenavinnercontainer i {
		margin-left: 5px;
		font-size: 17px;
	}

	.navdiv span {
		font-size: 15px;
		margin-left: 10px;
	}

	.fil span {
		font-size: 15px;
	}
}
