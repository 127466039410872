.awaitgrid{
    margin-top: 30px;
    display: grid;
    /* align-items: center;   */
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    gap: 40px;
    width: 100%;
    height: 100%;
    justify-content: center;
    justify-items: center;
}